import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"
import { sendEmail } from "../helpers/emailHelper"

// Layout
import KnockOut from "../components/layout/KnockOut"
import PodForm from "../components/forms/PodForm"

// Utility
import { Pods } from "../config/db"
import { AuthContext } from "../context/AuthContext"
import { podFormSchema } from "../helpers/validation"
import withWindow from "../helpers/withWindow"

const initialValues = phoneNumber => ({
  podName: "",
  phoneNumber: phoneNumber ? phoneNumber : "",
  emailAddress: "",
  zipCode: "",
  term: "",
  schoolName: "",
  grade: "",
  podSize: "",
  county: "",
  location: "",
  state: "",
  city: "",
  instructor: "",
  curriculum: "",
  cost: 0,
  paymentCycle: "",
  safety: [],
  preferences: [],
  technology: [],
  podSpirit: "",
  podSchedule: "",
  podCurriculum: "",
  podCost: "",
  podCommunication: "",
  podParentInvolvement: "",
  podTechnology: "",
  podConflict: "",
  podRules: "",
  toc: false,
  safetyAndScam: false,
  status: "Open",
})

const CreatePage = () => {
  const { user, hasPod, isAuth, isLoading } = useContext(AuthContext)

  useEffect(() => {
    if (hasPod) {
      navigate(`/inform?mode=exists&id=${hasPod.id}`)
    }

    if (!isAuth) {
      navigate("/signin?mode=create")
    }
  }, [hasPod, isAuth])

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = async ({ toc, safetyAndScam, ...pod }) => {
    pod.podSize = parseInt(pod.podSize)
    const { key: id } = await Pods.create(pod)
    sendEmail("created", { ...pod, id }, pod.emailAddress)
    navigate(`/inform?mode=create&id=${id}`)
  }

  return (
    <>
      <Helmet>
        <title>Create a pod</title>
      </Helmet>
      {isLoading ? (
        <KnockOut />
      ) : (
        <PodForm
          initialValues={initialValues(user?.phoneNumber)}
          onSubmit={handleSubmit}
          validationSchema={podFormSchema()}
        />
      )}
    </>
  )
}

export default withWindow(CreatePage)
